import React from "react";
import { Alert, Col, Container, Image, Row } from "react-bootstrap";
import SkillsIcon from "../assets/icons/requirements.png";
import Ideas from "../assets/icons/idea.png";
import Creativity from "../assets/icons/brain (1).png";
import Performance from "../assets/icons/good-feedback.png";
import Success from "../assets/icons/team.png";
import Project from "../assets/icons/folder.png";
import Creative from "../assets/creativity.png";
import WebIcon from "../assets/icons/mobile.png";
import TrainingIcon from "../assets/icons/training.png";
import InternshipIcon from "../assets/icons/internship.png";

const Skills = () => {
  return (
    <>
      <Container className="">
        <Row className="mx-0 py-3">
          <Col xs={4} md={2}>
            <div className="text-center">
              <Image src={SkillsIcon} alt="Skills" className="icons" />
              <h5 className="pt-1">Skills</h5>
            </div>
          </Col>
          <Col xs={4} md={2} className="text-center">
            <div className="text-center">
              <Image
                src={Ideas}
                alt="Skills"
                className="bg-success-subtle icons"
              />
              <h5 className="pt-1">Ideas</h5>
            </div>
          </Col>
          <Col xs={4} md={2} className="text-center">
            <div className="text-center">
              <Image
                src={Creativity}
                alt="Skills"
                className="bg-warning-subtle icons"
              />
              <h5 className="pt-1">Creativity</h5>
            </div>
          </Col>
          <Col xs={4} md={2} className="text-center">
            <div className="text-center">
              <Image
                src={Performance}
                alt="Skills"
                className="bg-info-subtle icons"
              />
              <h5 className="pt-1">Performance</h5>
            </div>
          </Col>
          <Col xs={4} md={2} className="text-center">
            <div className="text-center">
              <Image
                src={Success}
                alt="Skills"
                className="bg-danger-subtle icons"
              />
              <h5 className="pt-1">Success</h5>
            </div>
          </Col>
          <Col xs={4} md={2} className="text-center">
            <div className="text-center">
              <Image
                src={Project}
                alt="Skills"
                className="bg-primary-subtle icons"
              />
              <h5 className="pt-1">Upload Project</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <Row className="mx-0 py-3 px-5 bg-about">
        <Col md={7} className="pt-5">
          <h4 className="fw-bold text-danger pt-5">Who we are?</h4>
          <p>
            VD Talent Hub is a forward-thinking, innovative company specializing
            in connecting exceptional talent with leading organizations across
            various industries. We serve as a bridge between talented
            professionals seeking fulfilling opportunities and companies
            striving to build high-performing teams.
          </p>
          <p>
            We pride ourselves on our commitment to integrity, transparency, and
            excellence in service. Our team comprises dedicated experts who are
            passionate about unlocking potential and fostering success for both
            individuals and businesses. Whether it's permanent placements,
            contract roles, or project-based staffing, we offer tailored
            solutions to meet the unique needs of our clients. we aim to
            continually evolve and set new standards in the realm of talent
            acquisition and management.
          </p>
          <p>
            Ultimately, at Talent Hub, we believe in the transformative power of
            exceptional talent and its impact on driving organizational success
            and individual career growth.
          </p>
        </Col>
        <Col md={5} className="">
          <Image src={Creative} alt="Creativity" className="img-fluid" />
        </Col>
      </Row>
      <Container fluid className="py-3 px-4">
        <h4 className="text-danger text-center fw-bold py-2 mb-3">
          Our Services
        </h4>
        <Row className="mx-0 px-4">
          <Col md={4}>
            <Alert variant="warning">
              <h5 className="text-center">
                <Image
                  src={WebIcon}
                  alt="Web Design"
                  className="service-icon"
                />
              </h5>
              <h5 className="text-center">Web Design/Development</h5>
              <p>
                We specialize in bringing ideas to life through sleek design,
                intuitive user experiences, and robust functionality. Our team
                of experts merges artistic flair with technical expertise to
                build websites...
              </p>
            </Alert>
          </Col>
          <Col md={4}>
            <Alert variant="info">
              <h5 className="text-center">
                <Image
                  src={TrainingIcon}
                  alt="Web Design"
                  className="service-icon"
                />
              </h5>
              <h5 className="text-center">Online Training</h5>
              <p>
                We understand the importance of flexible learning in today's
                fast-paced world. Our platform provides a rich ecosystem where
                learners can explore a vast array of topics, from technical
                skills to personal...
              </p>
            </Alert>
          </Col>
          <Col md={4}>
            <Alert variant="danger">
              <h5 className="text-center">
                <Image
                  src={InternshipIcon}
                  alt="Web Design"
                  className="service-icon"
                />
              </h5>
              <h5 className="text-center">Internships</h5>
              <p>
                Our internship program is designed to provide aspiring
                individuals with a hands-on, immersive experience that serves as
                a launchpad for their careers. Our internships are crafted to
                offer a dynamic...
              </p>
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Skills;
