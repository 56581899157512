import React from "react";
import Footer from "../components/Footer";

const Career = () => {
  return (
    <>
      <h3>Career</h3>
    </>
  );
};

export default Career;
