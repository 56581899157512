import React from "react";
import {
  Badge,
  Col,
  Container,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import Logo from "../assets/talenthub.png";
import NotificationIcon from "../assets/icons/notification.png";
import UserIcon from "../assets/icons/user1.png";
import ProfilePic from "../assets/icons/profilepic.png";
import { LinkContainer } from "react-router-bootstrap";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-body-secondary py-0 sticky-top">
        <Container fluid>
          <Navbar.Brand href="#home">
            <Image src={Logo} alt="Talent hub" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to={"/Dashboard"} className="me-2 notification">
                <Nav.Link>
                  <Image src={NotificationIcon} alt="" className="nav-icon" />
                  <Badge bg="warning" className="notif">
                    0
                  </Badge>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={"/Dashboard"}>
                <Nav.Link>
                  <Image src={UserIcon} alt="" className="pic-icon" />{" "}
                  {/* <b>User name</b> */}
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Row className="mx-0 bg-dash">
        <Col md={2} className="bg-dark-subtle sidebar">
          <div className="text-center pt-3 pb-4 profile">
            <Image src={ProfilePic} alt="Profile Pic" className="profile-pic" />
            <h5 className="fw-bold">User name</h5>
          </div>
          <Nav
            defaultActiveKey="/User/Dashboard"
            className="flex-column py-2 side-nav"
          >
            <LinkContainer to={"/User/Dashboard"}>
              <Nav.Link>
                <i className="fa fa-dashboard"></i> Dashboard
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mycertificates"}>
              <Nav.Link eventKey="link-1">
                <i className="fas fa-award"></i> My Certificates
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/AllProjects"}>
              <Nav.Link eventKey="link-1">
                <i className="fas fa-project-diagram"></i> My Projects
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Myinternships"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-user-graduate"></i> My Internships
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mycourses"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-book-reader"></i> My Courses
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mysubscription"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-money-check"></i> My Subscription
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Quiz"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-question-circle"></i> Take Quiz
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Myprofile"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-user-edit"></i> My Profile
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Messages"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-comment-alt"></i> Messages
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/"}>
              <Nav.Link
                eventKey="link-2"
                className="fixed-bottom px-4 text-danger mb-3"
              >
                <i className="fas fa-sign-out-alt"></i> Log out
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Col>
        <Col className="">
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
