import React from "react";
import { Alert, Badge, Button, Col, Image, Row } from "react-bootstrap";
import Payment from "../assets/icons/member-card.png";
import Certificate from "../assets/icons/certificate.png";
import Star from "../assets/icons/star.png";
import Award from "../assets/icons/trophy.png";
import Pic from "../assets/icons/user1.png";
import HtmlCss from "../assets/courses/img-3.jpg";
import Fullstack from "../assets/courses/fullstackmern.jpg";
import Python from "../assets/courses/python.png";
import Reactnative from "../assets/courses/react-native-course.png";
import Spokenenglish from "../assets/courses/spoken-english.jpg";
import { LinkContainer } from "react-router-bootstrap";

const Projectupload = () => {
  return (
    <>
      <h4 className="pb-0 pt-3 d-flex justify-content-between align-items-center">
        All Projects{" "}
        <LinkContainer to={"/User/Newproject"}>
          <Button variant="primary" size="sm" className="px-4">
            New Project
          </Button>
        </LinkContainer>
      </h4>
      <Row className="mx-0 pt-0">
        <Col md={6} className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <h5 className="mb-0 d-flex justify-content-between align-items-center">
              Projects{" "}
              <span className="view-all">
                View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
              </span>
            </h5>
            <p>The top 25 projects</p>
            <hr />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript, React JS</span>
                  </small>
                  <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
          </Alert>
        </Col>
        <Col md={6} className="ps-1 pe-0">
          <Alert variant="light" className="shadow-sm">
            <h5 className="mb-0 d-flex justify-content-between align-items-center">
              My Projects{" "}
              <span className="view-all">
                View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
              </span>
            </h5>
            <p>Recently updated projects</p>

            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                  {/* <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small> */}
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  {/* <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small> */}
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="d-flex justify-content-between align-items-center quiz">
              <div>
                <p className="d-flex justify-content-between align-items-center">
                  <span>
                    <span className="fw-bold">Title:</span> Responsive & Retina
                  </span>
                  <span className="time text-danger">
                    <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                  </span>
                </p>
                <p className="desrciption">
                  <span className="fw-bold">Description: </span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,...
                </p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="small">
                    <span className="fw-bold">Skills:</span>{" "}
                    <span>HTML, CSS, JavaScript</span>
                  </small>
                  {/* <small className="small">
                    Designed by: <span>Jagadeesh</span>
                  </small> */}
                  <small className="small">
                    <span className="likes text-success" title="Likes">
                      <i className="fas fa-thumbs-up"></i> <span>10</span>
                    </span>{" "}
                    |{" "}
                    <span className="text-danger likes" title="Dislikes">
                      <i className="fas fa-thumbs-down"></i>{" "}
                      <span className="text-danger">0</span>
                    </span>
                  </small>
                  <small className="small likes text-warning">
                    <i className="fas fa-heart"></i>{" "}
                    <span className="text-warning">0</span>
                  </small>
                </div>
              </div>
            </div>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Projectupload;
