import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

const Newproject = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">Upload New Project</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Form>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Project Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. Ecommerce Website"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Duration</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. days or months (15 days)"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">
                      Project Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="About project here..."
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">
                      If you want any support?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="What type of support you want? type here..."
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Tools</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. HTML, CSS, JavaScript, Python"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">College</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. Vaagdevi Institue of Technology & Science"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Branch</Form.Label>
                    <Form.Control type="text" placeholder="Ex. CSE, ECE" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Year</Form.Label>
                    <Form.Control type="text" placeholder="Ex. 2020 - 2024" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Designed by</Form.Label>
                    <Form.Control type="text" placeholder="Ex. Vikranth" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">Role</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ex. Front end developer"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">
                      Project Document (.pdf)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Ex. Ecommerce Website"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-1">
                      Project Code (.zip)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Ex. days or months (15 days)"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="danger" className="w-100 mt-2">
                Upload Project
              </Button>
            </Form>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Newproject;
