import React from "react";
import { Alert, Button, Card, Col, Image, Row } from "react-bootstrap";
import Fullstack from "../assets/courses/fullstackmern.jpg";
import Reactnative from "../assets/courses/react-native-course.png";
import Spokenenglish from "../assets/courses/spoken-english.jpg";
import User from "../assets/icons/user.png";

const Mysubscription = () => {
  const videofiles = "https://vdsolution.in/videos/video1940577295.mp4";
  return (
    <>
      <h4 className="pb-1 pt-3">Subscription Plans</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="secondary" className="shadow-sm border-0 py-5 px-5">
            <Row>
              <div className="d-flex justify-content-center align-items-center">
                <span className="bg-danger text-white recommend">
                  Recommended
                </span>
              </div>
              <Col></Col>
              <Col md={3}>
                <Alert variant="light" className="shadow-sm">
                  <h1 className="mb-2 text-center">
                    {/* <i className="fas fa-user-circle plan-icon"></i> */}
                    <Image src={User} alt="" className="plan-icon" />
                  </h1>
                  <h5 className="text-center mb-3 plan">Bronze Plan</h5>
                  <p className="text-center text-secondary mb-1 lh-1">
                    <small>Access to upload only 1 project per year</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>No accesible to view all projects</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>No accesible to view contacts</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Only 1 quiz per month</small>
                  </p>
                  <p className="text-center text-secondary lh-1">
                    <small>No access to interact with contacts</small>
                  </p>
                  <h1 className="price plan d-flex justify-content-center align-items-center gap-1">
                    <small>
                      <i className="fa fa-rupee"></i>
                    </small>{" "}
                    <span>0</span> <small>/ year</small>
                  </h1>
                  <h1 className="text-center">
                    <Button variant="outline-danger" className="px-4">
                      Get Started
                    </Button>
                  </h1>
                </Alert>
              </Col>
              <Col md={3}>
                <Alert variant="light" className="shadow-sm rounded-bottom">
                  <h1 className="mb-2 text-center text-danger">
                    <Image src={User} alt="" className="plan-icon" />
                  </h1>
                  <h5 className="text-center text-danger mb-3">Diamond Plan</h5>
                  <p className="text-center text-secondary mb-1 lh-1">
                    <small>Access to upload upto 12 projects per year</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Accesible to view all projects</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Accesible to view contacts</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Unlimited quiz</small>
                  </p>
                  <p className="text-center text-secondary lh-1">
                    <small>Access to interact with contacts</small>
                  </p>
                  <h1 className="price text-danger d-flex justify-content-center align-items-center gap-1">
                    <small>
                      <i className="fa fa-rupee"></i>
                    </small>{" "}
                    <span>499</span> <small>/ year</small>
                  </h1>
                  <h1 className="text-center">
                    <Button variant="danger" className="px-4">
                      Get Started
                    </Button>
                  </h1>
                </Alert>
              </Col>
              <Col md={3}>
                <Alert variant="light" className="shadow-sm">
                  <h1 className="mb-2 text-center">
                    <Image src={User} alt="" className="plan-icon" />
                  </h1>
                  <h5 className="text-center mb-3 plan">Platinum Plan</h5>
                  <p className="text-center text-secondary mb-1 lh-1">
                    <small>Access to upload upto 24 projects per year</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Accesible to view all projects</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Accesible to view contacts</small>
                  </p>
                  <p className="text-center text-secondary mb-1">
                    <small>Unlimited quiz</small>
                  </p>
                  <p className="text-center text-secondary lh-1">
                    <small>Access to interact with contacts</small>
                  </p>
                  <h1 className="price plan d-flex justify-content-center align-items-center gap-1">
                    <small>
                      <i className="fa fa-rupee"></i>
                    </small>{" "}
                    <span>999</span> <small>/ year</small>
                  </h1>
                  <h1 className="text-center">
                    <Button variant="outline-danger" className="px-4">
                      Get Started
                    </Button>
                  </h1>
                </Alert>
              </Col>
              <Col></Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Mysubscription;
