import React, { useState } from "react";
import Footer from "../components/Footer";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import SliderImg from "../assets/banner.png";
import Mern from "../assets/MERN.png";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import BOOTSTRAP from "../assets/bootstrap.png";
import ES6 from "../assets/es6.png";
import MONGO from "../assets/monogdb.png";
import EXPRESS from "../assets/express.png";
import REACT from "../assets/react.png";
import NODE from "../assets/node.png";
import MYSQL from "../assets/mysql.png";
import Certificate from "../assets/certificate.png";
import PayIcon from "../assets/right-arrow.png";

const Internships = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="slide">
        <img src={SliderImg} alt="Slider" className="img-fluid" />
        <div className="career">
          <h3>Fullstack Development - MERN Internship</h3>
          <Alert variant="success" className="my-0 py-1 opacity-75 fw-bold">
            Industry Skills | Develop a Real World Project
          </Alert>
          <p className="py-2">
            This Fullstack Development - MERN Internship Program will provide
            the skills you need to become an MERN Fullstack Developer with ample
            hands on experience and dedicated Mentor support.
          </p>
          <a href="#payment" className="btn btn-danger px-5">
            Enroll Now
          </a>
        </div>
      </div>
      <Row className="mx-0 px-5 py-4 bg-light">
        <Col md={3}>
          <Alert variant="warning" className="d-flex justify-content-evenly">
            <h1 className="">
              <i className="fas fa-clock"></i>
            </h1>
            <div>
              <h5 className="mb-1">45 Days Duration</h5>
              <p className="">100 - 150 Hrs</p>
            </div>
          </Alert>
        </Col>
        <Col md={3}>
          <Alert variant="info" className="d-flex justify-content-evenly">
            <h1 className="">
              <i className="fas fa-project-diagram"></i>
            </h1>
            <div>
              <h5 className="mb-1">Real World Projects</h5>
              <p className="">10 - 15 Hrs per week</p>
            </div>
          </Alert>
        </Col>
        <Col md={3}>
          <Alert variant="danger" className="d-flex justify-content-evenly">
            <h1 className="">
              <i className="fas fa-clock"></i>
            </h1>
            <div>
              <h5 className="mb-1">Interview Preparation</h5>
              <p className="">15 days</p>
            </div>
          </Alert>
        </Col>
        <Col md={3}>
          <Alert variant="success" className="d-flex justify-content-evenly">
            <h1 className="">
              <i className="fas fa-clock"></i>
            </h1>
            <div>
              <h5 className="mb-1">20 Hrs of Self-paced</h5>
              <p className="">Learning Content</p>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="mx-0 px-5 pb-4">
        <Col md={1}></Col>
        <Col md={5} className="ps-5">
          <img src={Mern} alt="MERN" className="img-fluid" />
        </Col>
        <Col md={5} className="pe-5">
          <h3>Why MERN Stack?</h3>
          <p>
            Our Practical Learning Approach helps you learn the most in-demand
            tech skills from Industry Experts in 100% Live Interactive Sessions.
          </p>
          <ul>
            <li>It makes the development process easier and faster</li>
            <li>
              Used in top tech companies such as Facebook, Walmart, LinkedIn,
              Paypal etc
            </li>
            <li>
              Only the knowledge of javascript is essential for front-end and
              back-end development
            </li>
          </ul>
        </Col>
        <Row className="px-5 py-3">
          <Col className="bg-info-subtle rounded-2 py-3">
            <h3 className="px-4 pb-3">Technologies you will learn</h3>
            <div>
              <img src={HTML} alt="Html" className="px-4" />
              <img src={CSS} alt="Css" className="px-4" />
              <img src={BOOTSTRAP} alt="Bootstrap" className="px-4" />
              <img src={ES6} alt="Es6" className="px-4" />
              <img src={REACT} alt="React" className="px-4" />
              <img src={EXPRESS} alt="Express" className="px-4" />
              <img src={NODE} alt="Node" className="px-4" />
              <img src={MONGO} alt="Mongo" className="px-4" />
              <img src={MYSQL} alt="MySQL" className="px-4" />
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="mx-0 px-5 py-4 certificate">
        <h2 className="text-center text-decoration-underline pb-4">
          Internship & Project Completion Certificate
        </h2>
        <Col md={1}></Col>
        <Col md={5} className="py-5">
          <p className="pt-5">
            Get an official Internship Completion Certificate. To be awarded to
            learners who will build their own product and perform the best.
            {/* <b> Learners also get a chance to win a Laptop.</b> */}
          </p>
          <p>
            Our training is based on latest technologies which makes you ready
            for the industry. VD Talent hub will Present this certificate to
            students upon successful completion of the internship which will
            encourage and add to trainee’s resume to explore a lot of
            opportunities beyond position
          </p>
        </Col>
        <Col md={5} className="border rounded-2">
          <img
            src={Certificate}
            alt="Certificate"
            className="img-fluid opacity-75 rounded-2"
          />
        </Col>
      </Row>
      <Row className="mx-0 px-5 py-4 curriculum">
        <h2 className="text-center text-decoration-underline pb-4">
          Fullstack Developer - MERN Curriculum
        </h2>
        <Col md={6}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>Introduction to Fullstack development</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Intro to Fullstack
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  What is Frontend?
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  What is Backend?
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Roles & Responsibility for Full Stack Developer
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Environment Setup
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b>Module 1 - HTML & CSS</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to HTML
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  HTML Tags & Attributes
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  HTML Headings & Paragraphs
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  HTML Styles & Comments
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  HTML Tables, Lists & Forms
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  HTML Images & Semantic Elements
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS into HTML (Inline, Internal, External)
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Box Model, Fonts, Links, Lists, Tables
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Colors, Borders, Background, Margins, Padding
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Selectors
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Media Queries
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Animations
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CSS - Layout
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b>Module 2 - Bootstrap</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to Bootstrap
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Grid System, Typography, Colors.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Tables, Images, Alerts
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Buttons, Badges, Dropdowns, Forms, Input, Modal.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Navs, Navbar, Carousel, Popover.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b>Module 3 - JavaScript</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to Javascript
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Variables & Data types
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Operators and control statements
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Conditional Statements & Loop Statements
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Functions & Classes
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Strings, Numbers, Array's & Objects
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  DOM (Document Object Model)
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b>Module 4 - ES6</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to ES6
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  let, const, template string.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Arrow function, Spread operator.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  destructing, Promise.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Class, Inheritance.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={6}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>Module 5 - React JS</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Environment Setup
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introducing JSX
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Rendering Elements & State and Props
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  React List, Key, Refs, Fragments, CSS, Map, Table, Button,
                  Redux
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  React Forms, Events, Conditional Rendering
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b>Module 6 - Express JS</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to Express JS
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Routing, middleware
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Templating engines
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Error handling
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Sessions and cookies
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  File uploads, authentication
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  REST APIs
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b>Module 7 - Node JS</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Node introduction and installation.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Global objects- process, _dirname,_filename.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Node Modules- path, v8, fs, readline,
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  http Node Server creation
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b>Module 8 - MySQL</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to Database
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Creating Database & Table
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CRUD Operation on database tables
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Basic SQL Operations
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  MYSQL Joins
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b>Module 9 - Mongo DB</b>
              </Accordion.Header>
              <Accordion.Body>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Introduction to MongoDB and NoSQL databases.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Data modeling in MongoDB.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  CRUD Operation on database tables
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Indexing strategies in MongoDB.
                </p>
                <p className="d-flex align-items-center mb-1">
                  <small>
                    <i className="fas fa-star pe-2"></i>
                  </small>
                  Working with MongoDB and Node.js.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <Row id="payment" className="mx-0 px-5 py-5 payment">
        <Col md={7}>
          <Alert variant="light" className="px-5">
            <h6 className="mb-0 pt-3 fw-bold">BECOME AN</h6>
            <h4 className="fw-bold text-dark under">
              FULL STACK DEVELOPER - MERN
            </h4>

            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              120+ Hrs. of project-based learning
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              45 Days Duration (10-15 Hrs. a week)
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              Develop Real World Projects
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              Dedicated Mentor Support
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              Access to learning resources
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              2 Assignments & 1 Grand Assessment
            </p>
            <p className="d-flex align-items-center mb-1 ps-4">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              Complete your Mini / Major Projects
            </p>
            <p className="d-flex align-items-center mb-1 ps-4 pb-3">
              <small className="pe-2">
                <img src={PayIcon} />
              </small>
              Participate in Challenges & get a chance to win Laptop
            </p>
          </Alert>
        </Col>
        <Col md={5}>
          <Alert variant="light" className="px-5">
            <Badge bg="success" className="my-3">
              Super Early Bird Offer
            </Badge>
            <h5 className="text-decoration-line-through mb-0">
              <i className="fa fa-rupee pe-2 fs-6"></i>5,300
            </h5>
            <h1 className="fw-bold text-dark pb-3">
              <i className="fa fa-rupee pe-3 fs-3"></i>3,500
            </h1>
            <p className="pb-2">
              Start Learning today! Enroll today and get an instant off of Rs.
              1,800 as an Early Bird Offer.
            </p>
            <p className="fw-bold">
              The Super Early Bird Offers will Expire Soon!!!
            </p>
            <Button
              variant="success"
              className="w-100 mb-3"
              onClick={handleShow}
            >
              Enroll Now
            </Button>
          </Alert>

          <Modal
            show={show}
            size="lg"
            onHide={handleClose}
            className="rounded-0"
          >
            <Modal.Body className="p-0 rounded-0">
              <Row className="mx-0">
                <Col
                  md={6}
                  className="bg-success px-3 py-4 text-white bg-opacity-75"
                >
                  <h4 className="pb-3">Full Stack Internship Program</h4>
                  <ul>
                    <li className="pb-3">
                      Master HTML5, CSS3, Javascript & MERN Stack
                    </li>
                    <li className="pb-3">
                      Build an interactive web product like Flipkart with
                      hands-on training
                    </li>
                    <li className="pb-3">
                      Learn in Live and Instructor-led online classes by
                      industry experts
                    </li>
                  </ul>
                </Col>
                <Col md={6} className="bg-light px-3 py-4">
                  <h5>Let's Get Started</h5>
                  <Form>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="mb-1">Fullname*</Form.Label>
                      <Form.Control type="text" placeholder="Enter fullname*" />
                    </Form.Group>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="mb-1">Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone number*"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="mb-1">Email Id*</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email*"
                      />
                    </Form.Group>
                    <Button
                      type="submit"
                      variant="success"
                      className="w-100 mt-2"
                    >
                      Submit form
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Row className="mx-0 px-5 py-4 curriculum">
        <h3 className="text-center text-decoration-underline py-3">
          Frequently asked questions
        </h3>
        <Col md={1}></Col>
        <Col md={10}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <b>Who is this Full Stack Developer Internship course for?</b>
              </Accordion.Header>
              <Accordion.Body>
                Anyone interested in Full Stack Web Development can join this
                program. We don't have any prerequisites with regards to your
                educational qualifications or work experience.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <b>
                  What are the prerequisites to enrol in this Full Stack Web
                  Development Internship course?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                You can join even if you have zero programming knowledge. We
                will be teaching you everything from level 0.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <b>
                  What is the mode of Full Stack Web Developer Intern training?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                This Full Stack Internship Program is 100% Online with Live
                Instructor-led sessions. We offer our learners a complete
                hands-on Internship Like Experience.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <b>
                  Who will be the trainer for the Full Stack Developer
                  Internship Program?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                The trainers at VD Solutions are industry experts with above 8+
                years of experience in software development.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <b>
                  How much time do I need to spend per week to do well in this
                  full stack internship course?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                You will need to give at least 2 hours every day or at least 16
                hours every week to do well in this course. This includes
                attending 6 to 8-hour live instructor-led classes every week and
                attending 1 hour doubt solving sessions twice every week.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <b>
                  Will I get an Internship certificate irrespective of my
                  performance?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                No. You will only get an VD Solutions Internship Certificate if
                you complete the project within the timeline. We will give you
                adequate time, required guidance, and help to complete the
                project, but if you still fail, you will not get a Course
                Completion Certificate.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                <b>What do Web developer interns do?</b>
              </Accordion.Header>
              <Accordion.Body>
                A web developer intern is assigned a project that involves
                creating or managing websites. They Create website layout/user
                interface, Integrate data from different back-end applications
                and databases. Develop and improve specifications and
                specifications based on technical requirements.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                <b>
                  How is this Full Stack Web Development Internship program
                  different from other programs?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                The course is designed for students and recent graduates looking
                to gain relevant skills and experience in the Full-Stack Web
                Development domain. This course will follow a similar training
                structure as followed by top tech companies to train their
                employees. From Day 1, you will start building a real-world
                product like Flipkart, Netflix, and Zomato, and after the
                successful completion of this course, you will get an Internship
                certificate from VD Solutions.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                <b>
                  What skills will I learn during the Full Stack Web Development
                  Internship Program?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                Upon completion of this program you will be able to:
                <ul>
                  <li>
                    Build frontend of the product by using HTML5, CSS3, and
                    Bootstrap from the ground up
                  </li>
                  <li>
                    Learn about JavaScript, Data Structures, and Algorithms
                    beginning from the beginning
                  </li>
                  <li>
                    Learn about the components that make up the backend in the
                    MERN Stack - Express.js, Node.js, and MongoDB/MySQL starting
                    from beginning to finish
                  </li>
                  <li>
                    Learn and Implement React from scratch to reduce the
                    complexity of your frontend code and increase the
                    performance of your website.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                <b>
                  Will I get a paid internship offer or full-time offer from VD
                  Solutions or any other organization after this course?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                This is an internship program. After the completion of this Full
                Stack Internship program, we do not guarantee any paid
                internship or full-time offer with VD Solutions or any other
                organization. However, in this program, we will guide you on how
                to get a paid internship or a full-time offer. We may refer you
                as well if there’s a suitable opening in VD Solutions or any of
                its partner hiring companies.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                <b>How can I contact you?</b>
              </Accordion.Header>
              <Accordion.Body>
                For any queries, you can write to us at
                internships@vdsolution.in
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Internships;
