import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Fullstack from "../assets/courses/fullstackmern.jpg";

const Myinternships = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">My Internships</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              <Col md={3}>
                <Card className="">
                  <Card.Img
                    variant="top"
                    src={Fullstack}
                    className="img-fluid"
                  />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      Fullstack Development - MERN Internship
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 45 Days
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Myinternships;
