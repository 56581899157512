import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import LoginImg from "../assets/goals.png";
import User from "../assets/icons/user.png";
import Password from "../assets/icons/password.png";
import ProfileIcon from "../assets/icons/sign-up.png";
import Mobile from "../assets/icons/smartphone.png";
import Gmail from "../assets/icons/email.png";
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <>
      <div className="bg-signin">
        <Row className="mx-0">
          <Col
            md={7}
            className="position-absolute top-50 start-50 translate-middle"
          >
            <Row className="shadow-lg">
              <Col md={7} className="rounded-start-4 bg-white p-5">
                <div className="text-center pb-0">
                  <Image src={ProfileIcon} alt="Profile" className="pro-pic" />
                </div>
                <h4 className="text-center pb-2">Create Account</h4>
                <Form className="px-5 py-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <Image src={User} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Name"
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="mobile">
                      <Image src={Mobile} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Mobile"
                      aria-label="Mobile"
                      aria-describedby="mobile"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="gmail">
                      <Image src={Gmail} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Email id"
                      aria-label="Email"
                      aria-describedby="gmail"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      <Image src={Password} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                    />
                  </InputGroup>

                  <Button variant="success" className="w-100">
                    Sign Up
                  </Button>
                  {/* <div className="mt-3 text-center">
                    <Link to={""}>Forgot your password?</Link>
                  </div> */}
                </Form>
              </Col>
              <Col md={5} className="bg-sign rounded-end-4">
                <Image
                  src={LoginImg}
                  alt="Login"
                  className="img-fluid opacity-25"
                />
                <div className="text-center sign-text">
                  <h5 className="text-white">
                    Welcome to{" "}
                    <span className="text-warning">VD Talent hub</span>
                  </h5>
                  <p className="text-white-50">
                    Signing up with Talent Hub opens doors to a world of
                    opportunities and connections.
                  </p>
                </div>
                <div className="text-center mt-5 pt-5">
                  <Link to={"/Signin"}>
                    <Button variant="outline-warning" className="px-5">
                      Sign In
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Signup;
