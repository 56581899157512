import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import HtmlCss from "../assets/courses/img-3.jpg";
import Fullstack from "../assets/courses/fullstackmern.jpg";
import Python from "../assets/courses/python.png";
import Reactnative from "../assets/courses/react-native-course.png";
import Spokenenglish from "../assets/courses/spoken-english.jpg";
import Videofile from "../assets/videos/Daelyte_edited.mp4";

const Mycourses = () => {
  const videofiles = "https://vdsolution.in/videos/video1940577295.mp4";
  return (
    <>
      <h4 className="pb-1 pt-3">My Courses</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              {/* <Col>
                <Card className="">
                  <Card.Img variant="top" src={HtmlCss} className="img-fluid" />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      Learn Complete Front-End Web Development Course
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 45 Days
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col md={3}>
                <Card className="">
                  <Card.Img
                    variant="top"
                    src={Fullstack}
                    className="img-fluid"
                  />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      The Full Stack Web Development - MERN STACK
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 3 - 6
                        Months
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col md={3}>
                <Card className="">
                  <Card.Img variant="top" src={Python} className="img-fluid" />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      Learn Python Programming - Beginner to Master
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 1 - 2
                        Months
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col md={3}>
                <Card className="">
                  <Card.Img
                    variant="top"
                    src={Reactnative}
                    className="img-fluid"
                  />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      Learn React Native - Mobile App Development
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 1 - 2
                        Months
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="">
                  <Card.Img
                    variant="top"
                    src={Spokenenglish}
                    className="img-fluid"
                  />
                  <Card.Body className="pt-0 px-1 pb-1">
                    <p className="course-title pb-1 mb-0">
                      Speak English With Confidence: English Speaking Course
                    </p>
                    <p className="d-flex justify-content-between align-items-center mb-0">
                      <span className="course-details">
                        <i className="fas fa-book-reader"></i> Course: 30 Days
                      </span>
                      <span className="text-success fw-semibold">Joined</span>
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Alert>
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              <Col md={3}>
                <video
                  width="350"
                  height="200"
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <source src={videofiles} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Mycourses;
