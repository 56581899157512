import React from "react";
import Slider from "../components/Slider";
import Skills from "../components/Skills";

const Default = () => {
  return (
    <>
      <Slider />
      <Skills />
    </>
  );
};

export default Default;
