import React from "react";
import { Alert, Col, Image, Row } from "react-bootstrap";
import Certificate from "../assets/certificate.png";

const MyCertificates = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">My Certificates</h4>
      <Row className="mx-0 pt-0">
        <Col md={3} className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Image src={Certificate} alt="Certificate" className="img-fluid" />
          </Alert>
        </Col>
        <Col md={3} className="ps-1 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Image src={Certificate} alt="Certificate" className="img-fluid" />
          </Alert>
        </Col>
        <Col md={3} className="ps-1 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Image src={Certificate} alt="Certificate" className="img-fluid" />
          </Alert>
        </Col>
        <Col md={3} className="ps-1 pe-0">
          <Alert variant="light" className="shadow-sm">
            <Image src={Certificate} alt="Certificate" className="img-fluid" />
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default MyCertificates;
