import React from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import ProfilePic from "../assets/icons/profilepic.png";

const Myprofile = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">My Profile</h4>
      <Row className="mx-0 pt-0">
        <Col md={8} className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                  <Row>
                    <Col>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link eventKey="first">About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">Contacts</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="third">Settings</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tab.Content>
                        <Tab.Pane eventKey="first"></Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Row className="pt-3">
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                          </Row>
                          <Row className="pt-1">
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                          </Row>
                          <Row className="pt-1">
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                            <Col md={4}>
                              <Alert variant="light" className="shadow-sm pt-1">
                                <p className="d-flex align-items-center gap-2 mb-0">
                                  <span className="fs-1">
                                    <i className="fas fa-user-circle"></i>
                                  </span>
                                  <span className="contact">
                                    <span className="fw-bold d-block">
                                      Student name
                                    </span>
                                    <small>College name</small>
                                  </span>
                                </p>
                                <Button
                                  variant="outline-secondary"
                                  size="sm"
                                  className="me-1 px-4"
                                >
                                  Message
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="ms-1 px-4"
                                >
                                  Request
                                </Button>
                              </Alert>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                          <Row className="pt-3">
                            <h6>
                              <i className="fas fa-user-edit pe-1"></i> Personal
                              Info
                            </h6>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  First Name
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  Last Name
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">Mobile</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  Email Id
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Form.Label className="mb-1">Bio</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="pt-3">
                            <h6>
                              <i className="fas fa-user-graduate"></i> Education
                              Info
                            </h6>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  Qualification
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  College
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">Branch</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">Year</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="pt-3 pb-2">
                            <h6>
                              <i className="fas fa-address-card"></i> Address
                              Info
                            </h6>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  Address
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">City</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">State</Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group
                                className="mb-2"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label className="mb-1">
                                  Pincode
                                </Form.Label>
                                <Form.Control type="text" placeholder="" />
                              </Form.Group>
                            </Col>
                          </Row>
                          <p className="text-end mb-0">
                            <Button variant="info" className="px-5">
                              <i className="fas fa-save"></i> Update Profile
                            </Button>
                          </p>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Alert>
        </Col>
        <Col className="ps-1 pe-0">
          <Alert variant="light" className="shadow-sm">
            <Row className="user-det">
              <Col>
                <p className="text-center mb-0">
                  <Image src={ProfilePic} alt="Profile pic" className="" />
                </p>
                <h6 className="text-center fw-semibold">Vikranth Peetla</h6>
                <p className="text-center mb-2">
                  <small>Qualification</small>
                </p>
                <Table responsive>
                  <tbody className="bio">
                    <tr>
                      <td className="pb-1">
                        <span className="fw-bold">Mobile:</span>
                      </td>
                      <td className="pb-1">+91 - 9XXXX XXXXX</td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <span className="fw-bold">Email:</span>
                      </td>
                      <td className="py-1">user@gmail.com</td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <span className="fw-bold">College:</span>
                      </td>
                      <td className="py-1">College name</td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <span className="fw-bold">Branch:</span>
                      </td>
                      <td className="py-1">Branch name</td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <span className="fw-bold">Address:</span>
                      </td>
                      <td className="py-1">
                        Flat no. 43,Vikrampuri, Picket, Hyderabad, Telangana,
                        500016
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1">
                        <span className="fw-bold">Summary:</span>
                      </td>
                      <td className="py-1">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Alert>
          <Alert variant="light" className="shadow-sm">
            <Row className="user-det">
              <Col>
                <h6 className="fw-semibold mb-2 d-flex justify-content-between align-items-center">
                  Change Password{" "}
                  <Button variant="danger" size="sm">
                    Set password
                  </Button>
                </h6>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control type="text" placeholder="Enter Old password" />
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control type="text" placeholder="Enter New password" />
                </Form.Group>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Myprofile;
