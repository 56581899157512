import React from "react";
import Footer from "../components/Footer";

const Projects = () => {
  return (
    <>
      <h3>Projects</h3>
    </>
  );
};

export default Projects;
