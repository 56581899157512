import React from "react";
import { Alert, Badge, Button, Card, Col, Image, Row } from "react-bootstrap";
import Payment from "../assets/icons/member-card.png";
import Certificate from "../assets/icons/certificate.png";
import Star from "../assets/icons/star.png";
import Award from "../assets/icons/trophy.png";
import Pic from "../assets/icons/user1.png";
import HtmlCss from "../assets/courses/img-3.jpg";
import Fullstack from "../assets/courses/fullstackmern.jpg";
import Python from "../assets/courses/python.png";
import Reactnative from "../assets/courses/react-native-course.png";
import Spokenenglish from "../assets/courses/spoken-english.jpg";
import { LinkContainer } from "react-router-bootstrap";

const Userdashboard = () => {
  return (
    <>
      <div className="dash">
        <h4 className="pb-1 pt-3">Dashboard</h4>
        <div className="sub-expir shadow-sm d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-3">
            <Image
              src={Payment}
              alt=""
              className="sub-card pt-2 px-3 pb-3 bg-warning"
            />
            <p className="mb-0">Your subscription ends on 25 December 2025</p>
          </div>
          <LinkContainer to={"/User/Mysubscription"}>
            <Button variant="outline-danger" className="px-3 me-2">
              Upgrade
            </Button>
          </LinkContainer>
        </div>
        <Row className="mx-0 pt-2">
          <Col className="px-0 pt-1">
            <Alert variant="light" className="shadow-sm mb-0 pt-2">
              <h5 className="mb-1 d-flex justify-content-between align-items-center">
                Popular Courses{" "}
                <span className="view-all">
                  View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
                </span>
              </h5>
              <Row className="d-flex justify-content-between">
                <Col>
                  <Card className="">
                    <Card.Img
                      variant="top"
                      src={HtmlCss}
                      className="img-fluid"
                    />
                    <Card.Body className="pt-0 px-1 pb-1">
                      <p className="course-title pb-1 mb-0">
                        Learn Complete Front-End Web Development Course
                      </p>
                      <p className="d-flex justify-content-between align-items-center mb-0">
                        <span className="course-details">
                          <i className="fas fa-book-reader"></i> Course: 45 Days
                        </span>
                        <span className="buttn">Join</span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="">
                    <Card.Img
                      variant="top"
                      src={Fullstack}
                      className="img-fluid"
                    />
                    <Card.Body className="pt-0 px-1 pb-1">
                      <p className="course-title pb-1 mb-0">
                        The Full Stack Web Development - MERN STACK
                      </p>
                      <p className="d-flex justify-content-between align-items-center mb-0">
                        <span className="course-details">
                          <i className="fas fa-book-reader"></i> Course: 3 - 6
                          Months
                        </span>
                        <span className="buttn">Join</span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="">
                    <Card.Img
                      variant="top"
                      src={Python}
                      className="img-fluid"
                    />
                    <Card.Body className="pt-0 px-1 pb-1">
                      <p className="course-title pb-1 mb-0">
                        Learn Python Programming - Beginner to Master
                      </p>
                      <p className="d-flex justify-content-between align-items-center mb-0">
                        <span className="course-details">
                          <i className="fas fa-book-reader"></i> Course: 1 - 2
                          Months
                        </span>
                        <span className="buttn">Join</span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="">
                    <Card.Img
                      variant="top"
                      src={Reactnative}
                      className="img-fluid"
                    />
                    <Card.Body className="pt-0 px-1 pb-1">
                      <p className="course-title pb-1 mb-0">
                        Learn React Native - Mobile App Development
                      </p>
                      <p className="d-flex justify-content-between align-items-center mb-0">
                        <span className="course-details">
                          <i className="fas fa-book-reader"></i> Course: 1 - 2
                          Months
                        </span>
                        <span className="buttn">Join</span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="">
                    <Card.Img
                      variant="top"
                      src={Spokenenglish}
                      className="img-fluid"
                    />
                    <Card.Body className="pt-0 px-1 pb-1">
                      <p className="course-title pb-1 mb-0">
                        Speak English With Confidence: English Speaking Course
                      </p>
                      <p className="d-flex justify-content-between align-items-center mb-0">
                        <span className="course-details">
                          <i className="fas fa-book-reader"></i> Course: 30 Days
                        </span>
                        <span className="buttn">Join</span>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
        <Row className="mx-0 py-3">
          <Col md={6} className="ps-0 pe-1">
            <Alert variant="light" className="shadow-sm">
              <h5 className="mb-1 d-flex justify-content-between align-items-center">
                <span>
                  Certificates <Badge bg="info">4</Badge>
                </span>
                <LinkContainer to={"/User/Mycertificates"}>
                  <span className="view-all">
                    View all{" "}
                    <i className="fas fa-long-arrow-alt-right ps-1"></i>
                  </span>
                </LinkContainer>
              </h5>
              <hr className="mt-0" />
              <div className="d-flex gap-3">
                <Image
                  src={Certificate}
                  title="Name of Certificate"
                  alt="Certificate"
                  className="sub-card p-2 border rounded-4 opacity-75"
                />
                <Image
                  src={Certificate}
                  title="Name of Certificate"
                  alt="Certificate"
                  className="sub-card p-2 border rounded-4 opacity-75"
                />
                <Image
                  src={Certificate}
                  title="Name of Certificate"
                  alt="Certificate"
                  className="sub-card p-2 border rounded-4 opacity-75"
                />
                <Image
                  src={Certificate}
                  title="Name of Certificate"
                  alt="Certificate"
                  className="sub-card p-2 border rounded-4 opacity-75"
                />
              </div>
            </Alert>
            <Alert variant="light" className="shadow-sm">
              <h5 className="mb-0 d-flex justify-content-between align-items-center">
                Quizzes{" "}
                <span className="view-all">
                  View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
                </span>
              </h5>
              <p>Your recent performance</p>
              <hr />
              <div className="d-flex justify-content-between align-items-center quiz">
                <div>
                  <p className="">HTML & CSS Quiz</p>
                  <small className="small">
                    Topic: <span>Basic HTML & CSS</span>
                  </small>
                </div>
                <div className="text-center">
                  <h2 className="text-dark">5.8</h2>
                  <small className="small">Good</small>
                </div>
              </div>
              <hr className="mt-1" />
              <div className="d-flex justify-content-between align-items-center quiz">
                <div>
                  <p className="">Routing & Hooks</p>
                  <small className="small">
                    Topic: <span>React JS</span>
                  </small>
                </div>
                <div className="text-center">
                  <h2 className="text-success">9.5</h2>
                  <small className="small">Excellent</small>
                </div>
              </div>
              <hr className="mt-1" />
              <div className="d-flex justify-content-between align-items-center quiz">
                <div>
                  <p className="">Responsive & Retina</p>
                  <small className="small">
                    Topic: <span>Bootstrap Foundations</span>
                  </small>
                </div>
                <div className="text-center">
                  <h2 className="text-primary">7.9</h2>
                  <small className="small">Great</small>
                </div>
              </div>
              <hr className="mt-1" />
              <div className="d-flex justify-content-between align-items-center quiz">
                <div>
                  <p className="">Functions & Classes</p>
                  <small className="small">
                    Topic: <span>JavaScript</span>
                  </small>
                </div>
                <div className="text-center">
                  <h2 className="text-danger">3.4</h2>
                  <small className="small">Failed</small>
                </div>
              </div>
            </Alert>
          </Col>
          <Col md={6} className="ps-1 pe-0">
            <Alert variant="light" className="shadow-sm">
              <h5 className="mb-0 d-flex justify-content-between align-items-center">
                Rewards{" "}
                <span className="view-all">
                  View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
                </span>
              </h5>
              <p>Your latest achievements</p>
              <div className="d-flex align-items-center gap-3">
                <Image src={Star} alt="" className="rewards" />
                <Image src={Award} alt="" className="rewards" />
              </div>
            </Alert>
            <Alert variant="light" className="shadow-sm">
              <h5 className="mb-0 d-flex justify-content-between align-items-center">
                Forum Activity{" "}
                <span className="view-all">
                  View all <i className="fas fa-long-arrow-alt-right ps-1"></i>
                </span>
              </h5>
              <p>Latest forum topics & comments</p>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3 quiz">
                  <Image
                    src={Pic}
                    title="Name of Certificate"
                    alt="Certificate"
                    className="sub-pic opacity-75"
                  />
                  <div>
                    <p>Can someone help me with JavaScript?</p>
                    <small className="small">
                      Topic: <span className="pe-3">JavaScript</span> By:{" "}
                      <span>Raj</span>
                    </small>
                  </div>
                </div>
                <span className="time">
                  <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                </span>
              </div>
              <hr className="mt-1" />
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3 quiz">
                  <Image
                    src={Pic}
                    title="Name of Certificate"
                    alt="Certificate"
                    className="sub-pic opacity-75"
                  />
                  <div>
                    <p>Can someone help me with React JS?</p>
                    <small className="small">
                      Topic: <span className="pe-3">React JS</span> By:{" "}
                      <span>Raj</span>
                    </small>
                  </div>
                </div>
                <span className="time">
                  <i className="fa fa-clock-o pe-1"></i> <i>1 hr ago</i>
                </span>
              </div>
            </Alert>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Userdashboard;
