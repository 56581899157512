import React from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Html from "../assets/courses/HTML5.png";

const Quizz = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">Take Quiz</h4>
      <Row className="mx-0 pt-0">
        <Col md={9} className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              <Col>
                <h5 className="d-flex justify-content-between align-items-center mb-4">
                  <span>
                    Question 1 of 10{" "}
                    <small className="qtn">(Each question 10 pts.)</small>
                  </span>
                  <span className="text-danger">
                    00:20 <i className="far fa-clock clk"></i>
                  </span>
                </h5>

                <Row>
                  <p className="fw-semibold">
                    1. Which of the tag is used to creates a number list?
                  </p>
                  <Col md={6}>
                    <Alert variant="light" className="shadow-sm">
                      <Form.Check // prettier-ignore
                        type="radio"
                        id={`ans1`}
                        label="<li>"
                        name="qtn1"
                      />
                    </Alert>
                  </Col>
                  <Col md={6}>
                    <Alert variant="light" className="shadow-sm">
                      <Form.Check // prettier-ignore
                        type="radio"
                        id={`ans2`}
                        label="<ol>"
                        name="qtn1"
                      />
                    </Alert>
                  </Col>
                  <Col md={6}>
                    <Alert variant="light" className="shadow-sm">
                      <Form.Check // prettier-ignore
                        type="radio"
                        id={`ans3`}
                        name="qtn1"
                        label="<ul>"
                      />
                    </Alert>
                  </Col>
                  <Col md={6}>
                    <Alert variant="light" className="shadow-sm">
                      <Form.Check // prettier-ignore
                        type="radio"
                        id={`ans4`}
                        name="qtn1"
                        label="Both B & C"
                      />
                    </Alert>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col className="d-flex justify-content-between align-items-center">
                    <Button variant="outline-primary" className="px-3">
                      Skip Question
                    </Button>
                    <Button variant="danger" className="px-4">
                      Next Question
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Alert>
          <Alert variant="light" className="shadow-sm">
            <Row className="rules">
              <Col>
                <h6>Instructions:</h6>
                <ul>
                  <li>Each question contain 4 options</li>
                  <li>Each question have 20 seconds</li>
                  <li>For each right answer will get 10 points</li>
                  <li>Deduct 5 points for each wrong answer</li>
                  <li>
                    Every successfully completed quiz you will get 1 diamond
                  </li>
                </ul>
              </Col>
            </Row>
          </Alert>
        </Col>
        <Col className="ps-1 pe-0">
          <Alert variant="light" className="shadow-sm">
            <Row className="quiz-det">
              <Col>
                <h5 className="d-flex justify-content-between align-items-center mb-1">
                  Your Score{" "}
                  <span className="text-danger credits">
                    <i className="fa fa-diamond"></i> <sup>0</sup>
                  </span>
                </h5>
                <h3 className="text-center text-primary">0</h3>
                <p>
                  Total Quiz: <span className="fw-semibold">2</span>
                </p>
                <p>
                  Attempted: <span className="fw-semibold text-success">0</span>
                </p>
                <p>
                  Failed: <span className="fw-semibold text-danger">0</span>
                </p>
                <p className="text-info fw-bold py-1">
                  Today's Score: <span className="text-warning">0</span>
                </p>
                <small className="">
                  (Every successfully completed quiz you will get 1 diamond)
                </small>
              </Col>
            </Row>
          </Alert>
          <Alert variant="light" className="shadow-sm">
            <Row className="quiz-det">
              <Col>
                <h5 className="d-flex justify-content-between align-items-center mb-3">
                  Leader board
                  <span className="text-danger credits">
                    <i className="fas fa-trophy"></i>
                  </span>
                </h5>
                <Table responsive>
                  <thead>
                    <tr className="fw-bold">
                      <td>Name</td>
                      <td>Score</td>
                      <td>Rank</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fw-semibold">
                      <td className="text-danger">Student name</td>
                      <td className="text-danger">250</td>
                      <td className="text-center text-danger fw-bolder">1</td>
                    </tr>
                    <tr className="fw-semibold">
                      <td>Student name</td>
                      <td>230</td>
                      <td className="text-center">2</td>
                    </tr>
                    <tr className="fw-semibold">
                      <td>Student name</td>
                      <td>200</td>
                      <td className="text-center">3</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr className="urname">
                      <td className="text-success bg-warning-subtle">
                        Your name
                      </td>
                      <td className="text-success bg-warning-subtle">0</td>
                      <td className="text-center text-success bg-warning-subtle">
                        0
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default Quizz;
